import 'dayjs/locale/en-gb'
import 'dayjs/locale/da'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import weekYear from 'dayjs/plugin/weekYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import timezone from 'dayjs/plugin/timezone'
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(localizedFormat)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(timezone)
dayjs.extend(calendar)

dayjs.tz.setDefault('Europe/Copenhagen')

dayjs.updateLocale('da', {
  yearStart: 4,
})

dayjs.locale('da')
